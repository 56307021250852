<template>
  <div class="programs__wrapper">
    <div class="container">
      <div class="programs__content">
        <b-row cols="1" cols-sm="2" cols-md="3">
          <b-col v-for="program in programs" :key="program.id" class="program__container">
            <b-card
              tag="article"
              class="program__card"
              @click="
                $router.push({
                  name: 'Program',
                  params: { programId: program._id, page: $route.query.page },
                })
              "
            >
              <b-img class="program__image" :src="programImage(program)" alt="Fluid image"></b-img>
              <b-card-text class="program__info">
                <div class="program__title">
                  {{ program.name || $t('placeholders.newProgram') }}
                </div>
                <div
                  :class="[program.description ? 'body-medium' : 'body-small']"
                  class="program__description"
                >
                  {{ program.description || $t('placeholders.noDescription') }}
                </div>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </div>
      <Pagination
        v-if="numberOfPages > 1"
        :limit="paginationLimitButtons"
        :numberOfPages="numberOfPages"
      />
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination.vue';

export default {
  name: 'ProgramsContent',
  props: {
    programs: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    filter: {
      type: Object,
      required: true,
    },
    createProgram: {
      type: Function,
      required: true,
    },
  },
  components: {
    Pagination,
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.pagination.totalCount / this.pagination.perPage);
    },
    paginationLimitButtons() {
      const currentPage = this.$route.query.page ?? 1;
      if (+currentPage < 5 || +currentPage > this.numberOfPages - 4) {
        return 4;
      }
      return 5;
    },
  },
  methods: {
    programImage(program) {
      let image = require('@/assets/images/png/program-thumbnail.png');
      if (program.meta?.imageHighres) {
        image = `https://${
          // eslint-disable-next-line no-nested-ternary
          this.$device.isDesktop
            ? program.meta.imageHighres
            : this.$device.isTablet
              ? program.meta.imageMidres
              : program.meta.imageLowres
        }`;
      }
      return image;
    },
  },
};
</script>
