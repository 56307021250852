<template>
  <div>
    <b-pagination-nav
      :link-gen="linkGen"
      :number-of-pages="numberOfPages"
      :hide-goto-end-buttons="true"
      use-router
      align="right"
      :limit="limit"
      first-number
      last-number
    >
      <template #prev-text>
        <PrevArrow />
      </template>
      <template #next-text>
        <NextArrow />
      </template>
    </b-pagination-nav>
  </div>
</template>

<script>
import PrevArrow from '@/components/Icons/PrevArrow.vue';
import NextArrow from '@/components/Icons/NextArrow.vue';

export default {
  name: 'Pagination',
  components: { PrevArrow, NextArrow },
  props: {
    numberOfPages: {
      type: Number,
      required: true,
    },
    limit: {
      type: Number,
      default: 5,
    },
  },
  methods: {
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },
  },
};
</script>
